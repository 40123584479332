"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profitReportMonthOptions = exports.prevMonthsBasedOnFyEnd = exports.getArrayOfMonthsForData = exports.memberBpwEventStarted = exports.createMonthsArr = exports.FiscalYearEnd = exports.shortToLongMonthsBasedOnFYEnd = exports.shortMonthsBasedOnFYEnd = exports.fiscalYearForDay = exports.getYearBasedOnFYEndAndMonth = exports.getMonth = exports.TwoMonthAfterFYStart = exports.getFirstMonth = exports.FiscalYearStart = exports.GetWorkingDaysPerYear = exports.getWorkingDaysPerMonth = exports.getMonthYearValues = exports.isWeeklyWorkDay = exports.createAllMonthsArray = exports.createFirstXMonthsArray = exports.createLastXMonthsArray = exports.createLast3MonthsArray = exports.getFirstXmonths = exports.getLast3months = exports.getLastXmonths = exports.shortMonthsToValue = exports.getShortMonth = exports.LongMonthsToValue = exports.getCurrentFiscalYearsArray = exports.seasonalityOverrideInit = exports.decreasedDaysInit = exports.reverseShortMonthDynamoValue = exports.reverseShortMonthValue = exports.shortMonthsArrayDynamoDB = exports.shortMonthsArray = exports.shortMonthToLongMonthValue = exports.shortMonthValue = exports.monthValue = exports.Months = void 0;
const luxon_1 = require("luxon");
const Months = () => [
    { key: 0, name: "January" },
    { key: 1, name: "February" },
    { key: 2, name: "March" },
    { key: 3, name: "April" },
    { key: 4, name: "May" },
    { key: 5, name: "June" },
    { key: 6, name: "July" },
    { key: 7, name: "August" },
    { key: 8, name: "September" },
    { key: 9, name: "October" },
    { key: 10, name: "November" },
    { key: 11, name: "December" },
];
exports.Months = Months;
exports.monthValue = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
};
exports.shortMonthValue = {
    jan: 1,
    feb: 2,
    mar: 3,
    apr: 4,
    may: 5,
    jun: 6,
    jul: 7,
    aug: 8,
    sep: 9,
    oct: 10,
    nov: 11,
    dec: 12,
};
exports.shortMonthToLongMonthValue = {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
    december: "December",
};
exports.shortMonthsArray = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
];
exports.shortMonthsArrayDynamoDB = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "december",
];
exports.reverseShortMonthValue = {
    1: "jan",
    2: "feb",
    3: "mar",
    4: "apr",
    5: "may",
    6: "jun",
    7: "jul",
    8: "aug",
    9: "sep",
    10: "oct",
    11: "nov",
    12: "dec",
};
exports.reverseShortMonthDynamoValue = {
    1: "jan",
    2: "feb",
    3: "mar",
    4: "apr",
    5: "may",
    6: "jun",
    7: "jul",
    8: "aug",
    9: "sep",
    10: "oct",
    11: "nov",
    12: "december",
};
exports.decreasedDaysInit = {
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    checked: true,
};
exports.seasonalityOverrideInit = {
    jan: null,
    feb: null,
    mar: null,
    apr: null,
    may: null,
    jun: null,
    jul: null,
    aug: null,
    sep: null,
    oct: null,
    nov: null,
    dec: null,
    total: 0,
};
const getCurrentFiscalYearsArray = () => {
    const currentYear = luxon_1.DateTime.now().toUTC().year;
    const yearArray = [currentYear];
    for (let i = 1; i < 3; i++) {
        yearArray.push(currentYear - i);
    }
    return yearArray;
};
exports.getCurrentFiscalYearsArray = getCurrentFiscalYearsArray;
const LongMonthsToValue = (monthKey) => exports.monthValue[monthKey];
exports.LongMonthsToValue = LongMonthsToValue;
const getShortMonth = (value) => exports.reverseShortMonthValue[value];
exports.getShortMonth = getShortMonth;
const shortMonthsToValue = (monthKey) => exports.shortMonthValue[monthKey];
exports.shortMonthsToValue = shortMonthsToValue;
const getLastXmonths = (fiscalYear, fiscalEnd, numberOfMonths = 6) => {
    const monthsArray = [];
    for (let i = 0; i < numberOfMonths; i++) {
        monthsArray.push(luxon_1.DateTime.local(Number(fiscalYear), (0, exports.LongMonthsToValue)(fiscalEnd)).minus({
            months: i,
        }).month);
    }
    return monthsArray;
};
exports.getLastXmonths = getLastXmonths;
const getLast3months = (fiscalYear, fiscalEnd) => {
    const monthsArray = [];
    for (let i = 0; i < 3; i++) {
        monthsArray.push(luxon_1.DateTime.local(Number(fiscalYear), (0, exports.LongMonthsToValue)(fiscalEnd)).minus({
            months: i,
        }).month);
    }
    return monthsArray;
};
exports.getLast3months = getLast3months;
const getFirstXmonths = (fiscalYear, fiscalEnd, numberOfMonths = 6) => {
    const monthsArray = [];
    for (let i = 1; i < numberOfMonths + 1; i++) {
        monthsArray.push(luxon_1.DateTime.local(Number(fiscalYear), (0, exports.LongMonthsToValue)(fiscalEnd)).plus({
            months: i,
        }).month);
    }
    return monthsArray;
};
exports.getFirstXmonths = getFirstXmonths;
const createLast3MonthsArray = (fiscalYear, fiscalYearEnd) => {
    const last3Values = (0, exports.getLast3months)(Number(fiscalYear), fiscalYearEnd);
    const last3Months = [];
    last3Values.forEach((month) => {
        last3Months.push((0, exports.getShortMonth)(month));
    });
    return last3Months.reverse();
};
exports.createLast3MonthsArray = createLast3MonthsArray;
const createLastXMonthsArray = (fiscalYear, fiscalYearEnd, numberOfMonths = 6) => {
    const lastXvalues = (0, exports.getLastXmonths)(Number(fiscalYear), fiscalYearEnd, numberOfMonths);
    const lastXmonths = [];
    lastXvalues.forEach((month) => {
        lastXmonths.push((0, exports.getShortMonth)(month));
    });
    return lastXmonths.reverse();
};
exports.createLastXMonthsArray = createLastXMonthsArray;
const createFirstXMonthsArray = (fiscalYear, fiscalYearEnd, numberOfMonths = 6) => {
    const firstXvalues = (0, exports.getFirstXmonths)(Number(fiscalYear), fiscalYearEnd, numberOfMonths);
    const firstXmonths = [];
    firstXvalues.forEach((month) => {
        firstXmonths.push((0, exports.getShortMonth)(month));
    });
    return firstXmonths;
};
exports.createFirstXMonthsArray = createFirstXMonthsArray;
const createAllMonthsArray = (fiscalYear, fiscalYearEnd) => {
    const first6Months = (0, exports.createFirstXMonthsArray)(fiscalYear, fiscalYearEnd);
    const last6Months = (0, exports.createLastXMonthsArray)(fiscalYear, fiscalYearEnd);
    return first6Months.concat(last6Months);
};
exports.createAllMonthsArray = createAllMonthsArray;
const AddZeros = (n) => (n < 10 ? `0${n}` : n.toString());
const isWeeklyWorkDay = (weekday, workdays) => {
    if (weekday in workdays) {
        return workdays[weekday];
    }
    return false;
};
exports.isWeeklyWorkDay = isWeeklyWorkDay;
const weekDayName = (day) => {
    switch (day) {
        case 1:
            return "mon";
        case 2:
            return "tue";
        case 3:
            return "wed";
        case 4:
            return "thu";
        case 5:
            return "fri";
        case 6:
            return "sat";
        default:
            return "sun";
    }
};
/**
 * Creates obj of month values as key and correlating year as value based on fiscalYearEnd
 * @param year
 * @param fiscalYearEnd
 *
 */
const getMonthYearValues = (year, fiscalYearEnd) => {
    let monthYearObj = {};
    for (let i = 11; i >= 0; i--) {
        const monthNum = luxon_1.DateTime.local(year, exports.monthValue[fiscalYearEnd]).minus({
            months: i,
        }).month;
        monthYearObj = {
            ...monthYearObj,
            [monthNum]: luxon_1.DateTime.local(year, exports.monthValue[fiscalYearEnd]).minus({
                months: i,
            }).year,
        };
    }
    return monthYearObj;
};
exports.getMonthYearValues = getMonthYearValues;
/**
 *
 * @param weeklyWorkdays
 * @param fiscalYear
 * @param month
 * @param fiscalYearEnd
 * @param nonWorkingDays
 * @param decreasedWorkingDays
 */
function getWorkingDaysPerMonth(weeklyWorkdays, fiscalYear, month, fiscalYearEnd, nonWorkingDays, decreasedWorkingDays) {
    let year = fiscalYear;
    const monthKey = exports.reverseShortMonthValue[month];
    const monthYearValues = (0, exports.getMonthYearValues)(year, fiscalYearEnd);
    year = monthYearValues[month];
    const monthObj = luxon_1.DateTime.local(year, month);
    let { daysInMonth } = monthObj;
    const decreasedDays = decreasedWorkingDays
        ? decreasedWorkingDays[monthKey]
        : exports.decreasedDaysInit[monthKey];
    // Subtract non weekly workdays
    for (let i = 1; i <= monthObj.daysInMonth; i++) {
        const { weekday } = luxon_1.DateTime.fromFormat(`${year}-${AddZeros(month)}-${AddZeros(i)}`, "yyyy-MM-dd");
        if (!(0, exports.isWeeklyWorkDay)(weekDayName(weekday), weeklyWorkdays))
            daysInMonth--;
    }
    // subtract NonWorking Days (not a weekend)
    if (nonWorkingDays) {
        nonWorkingDays.forEach((nwd) => {
            const { weekday, month: nwdMonth } = luxon_1.DateTime.fromISO(nwd.date);
            if (nwdMonth === month &&
                (0, exports.isWeeklyWorkDay)(weekDayName(weekday), weeklyWorkdays)) {
                daysInMonth--;
            }
        });
    }
    return daysInMonth - decreasedDays;
}
exports.getWorkingDaysPerMonth = getWorkingDaysPerMonth;
const GetWorkingDaysPerYear = (weeklyWorkdays, currentYear, fiscalYearEnd, nonWorkingDays) => {
    var _a;
    let wd = 0;
    //Checking days in month
    if (fiscalYearEnd in exports.monthValue) {
        const date = luxon_1.DateTime.local(currentYear, exports.monthValue[fiscalYearEnd]);
        //Checking days in month
        for (let i = 0; i < 12; i++) {
            const newDate = date.minus({ month: i }).month;
            wd +=
                (_a = getWorkingDaysPerMonth(weeklyWorkdays, currentYear, newDate, fiscalYearEnd, nonWorkingDays)) !== null && _a !== void 0 ? _a : 0;
        }
    }
    return wd;
};
exports.GetWorkingDaysPerYear = GetWorkingDaysPerYear;
const FiscalYearStart = (year, month) => {
    if (month in exports.monthValue) {
        return luxon_1.DateTime.local(year, exports.monthValue[month])
            .minus({ months: 11 })
            .startOf("month")
            .toFormat("LLL dd, yyyy");
    }
};
exports.FiscalYearStart = FiscalYearStart;
const getFirstMonth = (year, month) => {
    if (month in exports.monthValue) {
        return luxon_1.DateTime.local(typeof year === "string" ? parseInt(year, 10) : year, exports.monthValue[month]).minus({ months: 11 }).month;
    }
    return 1;
};
exports.getFirstMonth = getFirstMonth;
const TwoMonthAfterFYStart = (year, month) => {
    if (month in exports.monthValue) {
        return luxon_1.DateTime.local(year, exports.monthValue[month])
            .minus({ months: 9 })
            .startOf("month")
            .toISODate();
    }
};
exports.TwoMonthAfterFYStart = TwoMonthAfterFYStart;
/**
 * Gets the correct month string based on params
 * @param month
 * @param prevOrNext
 */
function getMonth(month, prevOrNext) {
    const startLocation = exports.shortMonthsArrayDynamoDB.indexOf(month);
    // handle where we need to get the previous
    if (prevOrNext === "p") {
        if (startLocation === 0)
            return exports.shortMonthsArrayDynamoDB[11];
        else
            return exports.shortMonthsArrayDynamoDB[startLocation - 1];
    }
    // handle where we need to get the next
    if (prevOrNext === "n") {
        if (startLocation === 11)
            return exports.shortMonthsArrayDynamoDB[0];
        else
            return exports.shortMonthsArrayDynamoDB[startLocation + 1];
    }
}
exports.getMonth = getMonth;
/**
 * This takes fiscal year-end month and the fiscal year, creates the months and year object then returns the calendar year that month is in.
 *
 * @param fiscalYearEnd
 * @param fiscalYear
 * @param month
 */
function getYearBasedOnFYEndAndMonth(fiscalYearEnd, fiscalYear, month) {
    const monthYearValues = (0, exports.getMonthYearValues)(fiscalYear, fiscalYearEnd); // => {key: monthValue, value: fiscalYear for that month}
    return monthYearValues[exports.shortMonthValue[month]]; // returns year based on shortMonthsValue of month
}
exports.getYearBasedOnFYEndAndMonth = getYearBasedOnFYEndAndMonth;
//TP-1651 KRJ This finds the fiscal year of a given month and year, the above finds the year of a given month inside the given fiscal year
/**
 *Function to return the fiscal year of a supplied isodate string
 * @param date {string} isodate string
 * @param endMonth {string} endMonth of fiscal years
 * @return {number} the fiscal year of the given date
 * @author KRJ
 */
const fiscalYearForDay = (date, endMonth) => {
    const year = luxon_1.DateTime.fromISO(date).year;
    const month = luxon_1.DateTime.fromISO(date).month;
    //If the value of the given month is the same or less than the year-end month return given year
    //Else if given month is greater than year-end month return year + 1
    if (month <= (0, exports.LongMonthsToValue)(endMonth)) {
        return year;
    }
    else {
        return year + 1;
    }
};
exports.fiscalYearForDay = fiscalYearForDay;
const shortMonthsBasedOnFYEnd = (fiscalYear, fiscalYearEnd) => {
    const first6Months = (0, exports.createFirstXMonthsArray)(fiscalYear, fiscalYearEnd);
    const last6Months = (0, exports.createLastXMonthsArray)(fiscalYear, fiscalYearEnd);
    return first6Months.concat(last6Months);
};
exports.shortMonthsBasedOnFYEnd = shortMonthsBasedOnFYEnd;
const shortToLongMonthsBasedOnFYEnd = (fiscalYear, fiscalYearEnd) => {
    const shortMonths = (0, exports.shortMonthsBasedOnFYEnd)(fiscalYear, fiscalYearEnd);
    let shortToLong = {};
    shortMonths.forEach((month) => {
        shortToLong = {
            ...shortToLong,
            [month]: exports.shortMonthToLongMonthValue[month],
        };
    });
    return shortToLong;
};
exports.shortToLongMonthsBasedOnFYEnd = shortToLongMonthsBasedOnFYEnd;
const FiscalYearEnd = (year, month) => {
    if (month in exports.monthValue) {
        return luxon_1.DateTime.local(year, exports.monthValue[month])
            .endOf("month")
            .toFormat("LLL dd, yyyy");
    }
};
exports.FiscalYearEnd = FiscalYearEnd;
const createMonthsArr = (startDate) => {
    const months = [];
    for (let i = 0; i < 12; i++) {
        const month = startDate.plus({ months: i }).monthShort.toLowerCase();
        months.push(month);
    }
    return months;
};
exports.createMonthsArr = createMonthsArr;
const memberBpwEventStarted = (memberEvents, fiscalYear) => {
    const memberEventDate = luxon_1.DateTime.fromISO(memberEvents[fiscalYear].eventStart);
    return memberEventDate < luxon_1.DateTime.now();
};
exports.memberBpwEventStarted = memberBpwEventStarted;
function getArrayOfMonthsForData(monthStart, monthEnd) {
    if (monthStart === monthEnd)
        return [monthStart];
    const monthData = [monthStart];
    let monthsProcessed = 1;
    let monthToCheck = getMonth(monthStart, "n");
    while (monthToCheck !== monthEnd && monthsProcessed < 11) {
        monthData.push(monthToCheck);
        monthsProcessed++;
        monthToCheck = getMonth(monthToCheck, "n");
    }
    monthData.push(monthEnd);
    return monthData;
}
exports.getArrayOfMonthsForData = getArrayOfMonthsForData;
/**
 * Used for Month Selectors: Gets previous months based on FY End
 * @param selectedYear {int}
 * @param fyEnd {string}
 */
const prevMonthsBasedOnFyEnd = (selectedYear, fyEnd) => {
    const monthsBasedOnFYEnd = (0, exports.shortMonthsBasedOnFYEnd)(selectedYear, fyEnd);
    const monthsToReturn = [];
    // return all months if previous year
    if (selectedYear < luxon_1.DateTime.now().year) {
        monthsBasedOnFYEnd.forEach((month) => {
            monthsToReturn.push({
                text: exports.shortMonthToLongMonthValue[month],
                value: month,
            });
        });
        return monthsToReturn;
    }
    // this condition is triggered when a member's FY does not match the calendar year
    else if (selectedYear > luxon_1.DateTime.now().year) {
        const monthWithYearValues = (0, exports.getMonthYearValues)(selectedYear, fyEnd);
        Object.entries(monthWithYearValues).forEach(([month, year]) => {
            // only return the previous months in the current calendar year
            if (year === luxon_1.DateTime.now().year &&
                parseInt(month) < luxon_1.DateTime.now().month) {
                const shortMonth = exports.reverseShortMonthValue[month];
                monthsToReturn.push({
                    text: exports.shortMonthToLongMonthValue[shortMonth],
                    value: exports.reverseShortMonthValue[month],
                });
            }
        });
        return monthsToReturn;
    }
    else {
        // just return previous months in member's FY months array
        const currentMonthIdx = monthsBasedOnFYEnd.indexOf(exports.reverseShortMonthValue[luxon_1.DateTime.local().month]);
        if (currentMonthIdx > 0) {
            const shortMonthsArray = monthsBasedOnFYEnd.slice(0, currentMonthIdx);
            shortMonthsArray.forEach((month) => {
                monthsToReturn.push({
                    text: exports.shortMonthToLongMonthValue[month],
                    value: month,
                });
            });
        }
        return monthsToReturn;
    }
};
exports.prevMonthsBasedOnFyEnd = prevMonthsBasedOnFyEnd;
const profitReportMonthOptions = (selectedYear) => {
    const monthsToReturn = [];
    if (selectedYear) {
        if (selectedYear === luxon_1.DateTime.now().year) {
            Object.entries(exports.reverseShortMonthValue).forEach(([monthNum, shortMonth]) => {
                if (parseInt(monthNum) < luxon_1.DateTime.now().month) {
                    monthsToReturn.push({
                        text: exports.shortMonthToLongMonthValue[shortMonth],
                        value: monthNum,
                    });
                }
            });
        }
        else {
            Object.entries(exports.reverseShortMonthValue).forEach(([monthNum, shortMonth]) => {
                monthsToReturn.push({
                    text: exports.shortMonthToLongMonthValue[shortMonth],
                    value: monthNum,
                });
            });
        }
    }
    return monthsToReturn.reverse();
};
exports.profitReportMonthOptions = profitReportMonthOptions;
