import React, { Fragment, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import {
  chooseActionIcon,
  shouldDisableTool,
  toolData,
} from "../../home/utils/homePageHelpers";
import { useCompanyContext } from "../../context/company/CompanyContext";
import ChevronDownIcon from "../../../utils/components/icons/ChevronDownIcon";
import { useUserContext } from "../../context/user/UserContext";
import { useDirtyContext } from "../../context/dirty/DirtyContext";

type NavigationDropDownProps = {
  id: string;
  relatedTools?: Array<string>;
  title: string;
  userToolsAccess?: any;
  toolsToDisplay: string[];
};
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavigationDropDown({
  id,
  title,
  relatedTools,
  userToolsAccess,
  toolsToDisplay,
}: NavigationDropDownProps): ReactElement {
  const navigate = useNavigate();
  const { getCurrentFiscalYear, workshopEvents } = useCompanyContext();
  const { companyOptions } = useUserContext();
  const { handleOpenDirty } = useDirtyContext();
  const currentFiscalYear = getCurrentFiscalYear();
  const currentCalendarYear = new Date().getFullYear();

  const getDisabledStatus = (toolInfo) => {
    const isToolDisabled = shouldDisableTool(
      toolData[toolInfo].toolEnum,
      userToolsAccess,
      workshopEvents,
    );
    if (
      (toolData[toolInfo].toolEnum === "REPORTS" &&
        companyOptions.length > 1) ||
      isToolDisabled
    ) {
      return false;
    }
    return true;
  };

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            id={id}
            className={classNames(
              open ? "border-b-white" : "border-transparent",
              "inline-flex items-center gap-2 p-1 bg-NexstarBlue-dark text-[15px] text-white text-left font-bold hover:text-gray-100  border-t-2 border-t-transparent border-b-2 hover:border-b-white focus-visible:border-b-white focus:outline-none focus:ring-0 focus:ring-offset-0 transition",
            )}
          >
            <span>{title}</span>
            <ChevronDownIcon size="xxsmall" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 mt-3 w-screen max-w-[240px]">
              <div className="overflow-hidden rounded-tr-lg rounded-bl-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid bg-white">
                  {relatedTools.map((tool) => {
                    if (!toolsToDisplay.includes(tool)) return null;
                    return (
                      <button
                        id={`${toolData[tool].toolEnum}_desktop-nav`}
                        disabled={getDisabledStatus(tool)}
                        type="button"
                        key={toolData[tool].label}
                        onClick={() => {
                          handleOpenDirty(() => {
                            close();
                            navigate(
                              toolData[tool].link.replace(
                                ":year:",
                                `${
                                  !currentFiscalYear
                                    ? currentCalendarYear
                                    : currentFiscalYear
                                }`,
                              ),
                            );
                          });
                        }}
                        className={`flex flex-col py-3 px-4 transition ${
                          !getDisabledStatus(tool)
                            ? `cursor-pointer bg-white hover:bg-gray-100 grayscale-0`
                            : "cursor-not-allowed bg-NexstarGray grayscale"
                        }`}
                      >
                        <div className="flex items-center">
                          <div className="w-[25px] min-w-[25px]">
                            {chooseActionIcon(
                              toolData[tool].icon,
                              false,
                              getDisabledStatus(tool),
                            )}
                          </div>
                          <div className="ml-2.5">
                            <p className="text-lg leading-4 font-bold text-NexstarBlue text-left">
                              {toolData[tool].label}
                            </p>
                          </div>
                        </div>
                        {!getDisabledStatus(tool) ? null : (
                          <p className="text-[#747677] text-left text-xs mt-1">
                            {toolData[tool].toolEnum in userToolsAccess
                              ? toolData[tool].disabledDescription.replace(
                                  ":year:",
                                  `${
                                    !currentFiscalYear
                                      ? currentCalendarYear
                                      : currentFiscalYear
                                  }`,
                                )
                              : null}
                          </p>
                        )}
                      </button>
                    );
                  })}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
