import React, { Dispatch, ReactElement, SetStateAction, useState } from "react";
import NexstarModal from "../../../utils/components/tailwind/NexstarModal";
import CardHeader from "../../../utils/components/tailwind/CardHeader";
import Button from "../../../utils/components/tailwind/Button";
import { NexstarTextField } from "../../../utils/components/tailwind/NexstarTextField";
import { CRMUser } from "../../../utils/helpers/UserHelper";
import { useSnackbar } from "notistack";
import { addMessage } from "../../../utils/helpers/MessagingHelper";
import { reportErrorToServiceDesk } from "../utils/errorReportRequests";
import { useMutation } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { MemberType } from "@nexstar-network/shared-utils";
import { CreateJiraRequestType } from "../utils/errorReportingTypes";

export function ErrorReportDialog({
  open,
  setOpen,
  userData,
  companyData,
  eventId,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  userData: CRMUser;
  companyData: MemberType;
  eventId: string;
}): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();
  const [feedback, setFeedback] = useState<string>(null);

  const nickName = userData ? userData.nickname : "";
  const companyId = userData
    ? userData["https://nexstarcrm.com/app_metadata"].companyID
    : null;
  const summary = `MEMBER ISSUE - ${eventId}`;
  const description = `h2. Member: ${nickName} - ${companyId}

h4. Description of error:

${feedback}

{code:json}{
    "userData": ${JSON.stringify(userData, null, 2)},
    "companyData": ${JSON.stringify(companyData, null, 2)},
}{code}`;

  const reportData: CreateJiraRequestType = {
    serviceDeskId: "3",
    requestTypeId: "33",
    requestFieldValues: {
      description,
      summary,
      customfield_10089: "https://tools.nexstarnetwork.com/management",
    },
    raiseOnBehalfOf:
      "qm:2f7bdaa4-2048-4505-a797-279403adbfae:22cbe7e2-06d7-46dd-85f4-01a94a32ee0d",
  };

  const reportToSubmit = useMutation({
    mutationFn: (reportToSend: any) =>
      reportErrorToServiceDesk(reportToSend, getAccessTokenSilently),
  });

  const handleReportError = () => {
    reportToSubmit.mutate(
      {
        reportData,
      },
      {
        onSuccess: () => {
          addMessage("ERROR_REPORTED", "success", enqueueSnackbar);
        },
        onError: () => {
          // TP-1106 JAH: Error Handling
          addMessage("ERROR_REPORT_FAILED", "error", enqueueSnackbar);
        },
        onSettled: () => {
          setOpen(false);
        },
      },
    );
  };

  return (
    <NexstarModal
      id="errorReportModal"
      open={open !== null && open === true}
      onClose={() => setOpen(false)}
      maxWidth="max-w-[800px]"
    >
      <CardHeader title="Error Report" />
      <div className="flex flex-col gap-6 p-6 items-center">
        <div className="max-w-[500px] text-center">
          <p className="text-xl">
            Please let us know what you were doing while this error occurred so
            that we can get to work to remedy the issue.
          </p>
        </div>
        <div className="flex flex-col w-full">
          <NexstarTextField
            id="error_report_description"
            name="feedback"
            value={feedback}
            placeholder="Description of what caused the error"
            onChange={(ev) => setFeedback(ev.target.value)}
            textAlign="text-center"
          />
        </div>
        <div className="flex gap-4 justify-center">
          <Button
            id="sumbit_report_btn"
            onClick={() => handleReportError()}
            width="w-24"
          >
            Submit
          </Button>
        </div>
      </div>
    </NexstarModal>
  );
}
