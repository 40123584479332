import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CloseIcon from "../../../utils/components/icons/CloseIcon";
import CoachingTeamModal from "../../../utils/components/CoachingTeamModal";
import { useCompanyContext } from "../../context/company/CompanyContext";
import {
  chooseActionIcon,
  shouldDisableTool,
  toolData,
} from "../../home/utils/homePageHelpers";
import { useUserContext } from "../../context/user/UserContext";
import { useNavigate } from "react-router-dom";
import { useDirtyContext } from "../../context/dirty/DirtyContext";

type SideNavProps = {
  open: boolean;
  setOpen: any;
  isCoach: boolean;
  toolsToDisplay: any;
};
export default function SideNav({
  open,
  setOpen,
  isCoach,
  toolsToDisplay,
}: SideNavProps) {
  const {
    memberCoaches,
    workshopEvents,
    fetchingWorkshopEvents,
    getCurrentFiscalYear,
  } = useCompanyContext();
  const { userToolsAccess } = useUserContext();
  const { handleOpenDirty } = useDirtyContext();
  const currentFiscalYear = getCurrentFiscalYear();
  const currentCalendarYear = new Date().getFullYear();
  const navigate = useNavigate();
  const handleClick = (tool) => {
    if (tool === "DMT") {
      if (currentFiscalYear) {
        navigate(toolData[tool].link.replace(":year:", currentFiscalYear));
      } else {
        navigate(
          toolData[tool].link.replace(":year:", currentCalendarYear.toString()),
        );
      }
      setOpen(false);
    } else {
      navigate(toolData[tool].link);
      setOpen(false);
    }
  };

  const disableTool = (tool) => {
    if (tool !== "BPW") {
      return !shouldDisableTool(tool, userToolsAccess, workshopEvents);
    } else {
      // disabling BPW so they can't navigate there while bpwEvents is fetching
      return fetchingWorkshopEvents;
    }
  };

  const [openModal, setOpenModal] = useState(false);

  const sortToolsByName = () => {
    const orderedObjectByValue = Object.entries(toolsToDisplay).sort(
      ([, value1], [, value2]) => {
        if (value1 > value2) {
          return 1;
        } else if (value1 < value2) {
          return -1;
        }
        return 0;
      },
    );
    // SORT Returns an array so I have to handle it like this.
    const orderedEnum = [];
    orderedObjectByValue.forEach(([key]) => {
      orderedEnum.push(key);
    });
    return orderedEnum;
  };
  const getToolColor = (tool) => {
    if (toolData[tool]["toolType"] === "companyManagement") {
      return "border-NexstarSoftGold";
    } else if (toolData[tool]["toolType"] === "dailyTools") {
      return "border-NexstarSkyBlue";
    } else if (toolData[tool]["toolType"] === "financial") {
      return "border-NexstarLimeDark";
    } else if (toolData[tool]["toolType"] === "BPW") {
      return "border-NexstarOrange";
    } else if (toolData[tool]["toolType"] === "marketing") {
      return "border-NexstarBlue-light";
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[1300]" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                    <button
                      onClick={() => setOpen(!open)}
                      className={`group absolute -right-[48px] ${
                        isCoach ? "top-[46px]" : "top-[19px]"
                      } bg-NexstarBlue py-3 px-[14px] rounded-tr-lg rounded-br-lg`}
                      id="sideNavBtn"
                    >
                      <CloseIcon className="group-hover:fill-NexstarOrange" />
                    </button>
                    <div className="flex h-full flex-col bg-NexstarBlue shadow-xl">
                      <div className="flex min-h-0 flex-1 flex-col overflow-y-auto py-6">
                        <div className="relative flex-1 px-6">
                          {sortToolsByName().map((tool) => {
                            return (
                              <button
                                id={`${tool}-side-nav`}
                                key={tool}
                                onClick={() =>
                                  handleOpenDirty(() => handleClick(tool))
                                }
                                disabled={disableTool(tool)}
                                className={`group flex w-full gap-3 items-center mb-3 ${
                                  disableTool(tool) ? "cursor-not-allowed" : ""
                                }`}
                              >
                                <div
                                  className={`${
                                    disableTool(tool)
                                      ? "border-transparent bg-NexstarGray-dark"
                                      : `${getToolColor(
                                          tool,
                                        )} bg-white group-hover:border-2 group-hover:shadow-[0_0_20px_rgba(0,0,0,0.4)]`
                                  } rounded-full w-12 h-12 p-2 border-4 transition-all`}
                                >
                                  {chooseActionIcon(
                                    toolData[tool].icon,
                                    tool in userToolsAccess
                                      ? userToolsAccess[tool].beta
                                      : false,
                                    disableTool(tool),
                                  )}
                                </div>
                                <p
                                  className={`font-bold text-left leading-5 ${
                                    disableTool(tool)
                                      ? "text-NexstarGray-dark"
                                      : "text-white"
                                  }`}
                                >
                                  {toolData[tool]["label"]}
                                </p>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                      {memberCoaches ? (
                        <div className="flex flex-shrink-0 justify-center px-4 py-4 bg-NexstarBlue-dark">
                          <p className="text-white text-sm">
                            Have a question? Contact your&nbsp;
                          </p>
                          <button
                            className="text-white text-sm underline font-bold hover:text-NexstarOrange hover:no-underline transition"
                            onClick={() => {
                              setOpen(false);
                              setOpenModal(true);
                            }}
                          >
                            Coaching Team
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <CoachingTeamModal open={openModal} setOpen={setOpenModal} />
    </>
  );
}
